import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const HomepageStats = () => {
  let data = useStaticQuery(graphql`
    query HomepageCustomerQuery {
      prismicHomepage {
        data {
          stats_headline {
            html
            text
          }
          stats {
            icon {
              fixed(height: 75) {
                ...GatsbyPrismicImageFixed
              }
            }
            title {
              html
              text
            }
          }
        }
      }
    }
  `)
  data = data.prismicHomepage.data
  if (!data) return null
  
  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicHomepage) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicHomepage.data
  }

  return (
    <section className="homepage-stats">
      <div className="inner">
        <h3>{data.stats_headline.text}</h3>

        <div className="stats">
          {data.stats.map((stat, ind) => {
            return <div className="stat" key={ind} data-sal="slide-up" data-sal-delay={`${150 + (ind++ * 100)}`}>
              <div className="icon" style={{ 'backgroundImage': `url(${stat.icon.fixed.src})` }}></div>
              <p>{stat.title.text}</p>
            </div>
          })}
        </div>
      </div>
    </section>
  )
}

export default HomepageStats
