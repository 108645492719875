import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const HomepageCustomerLogos = () => {
  let data = useStaticQuery(graphql`
    query HomepageCustomersQuery {
      prismicHomepage {
        data {
          logos {
            customer {
              document {
                ... on PrismicCustomer {
                  id
                  data {
                    customer_logo {
                      fluid(maxHeight: 50) {
                        ...GatsbyPrismicImageFluid
                      }
                    }
                    customer_name {
                      html
                      text
                    }
                  }
                }
              }
            }
          }
          customers_headline {
            html
            text
          }
        }
      }
    }
  `).prismicHomepage.data

  if (!data || !data.logos) return null

  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicHomepage) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicHomepage.data
  }

  return (
    <section className="homepage-customer-logos">
      <div className="outer-lines"></div>
      <div className="inner">
        <h6>{data.customers_headline.text}</h6>

        <div className="logos">
          {data.logos.map((logo, ind) => {
            if (!logo.customer.document) return null

            return <div className="logo" key={ind} data-sal="slide-up" data-sal-delay={`${150 + (ind++ * 50)}`}>
              <Img loading="eager" fluid={logo.customer.document.data.customer_logo.fluid} imgStyle={{ objectFit: "contain" }} alt={logo.customer.document.data.customer_name.text} />
            </div>
          })}
        </div>
      </div>
      <div className="outer-spacer"></div>
    </section>
  )
}

export default HomepageCustomerLogos
