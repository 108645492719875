import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomepageProduct from "../components/homepage/product"
import HomepageCaseStudies from "../components/homepage/case-studies"
import HomepageCustomerLogos from "../components/homepage/customer-logos"
import HomepageStats from "../components/homepage/stats"
import HomepageBlog from "../components/homepage/blog"
import SimpleHero from "../components/simpleHero"

const IndexPage = () => {
  let simpleHeroData = useStaticQuery(graphql`
    query HomepageQuery {
      data: prismicHomepage {
        data {
          hero_title {
            html
            text
          }
          hero_text {
            html
            text
          }
          hero_link {
            url
            target
            type
            link_type
            uid
          }
          hero_link_text {
            html
            text
          }
          hero_image {
            url
          }
          hero_image_alt {
            url
          }
        }
      }
    }
  `).data.data

  return (
    <Layout>
      <SEO title="Home" />

      <SimpleHero data={simpleHeroData} />
      <HomepageProduct />
      <HomepageCaseStudies />
      <HomepageStats />
      <HomepageCustomerLogos />
      <HomepageBlog />
    </Layout>
  )
}

export default IndexPage
