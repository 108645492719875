import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import _ from "lodash"

import CustomLink from "../customLink"
import { triggerRequestDemoClick } from "../_helpers/triggerRequestDemoClick"

const HomepageProduct = () => {
  let data = useStaticQuery(graphql`
    query HomepageProduct {
      product_features: prismicHomepageBodyHomepageProductFeatures {
        items {
          product_feature_link {
            url
            target
            link_type
            type
          }
          product_feature_link_text {
            text
            html
          }
          product_feature_title {
            text
            html
          }
          product_feature_description {
            text
            html
          }
          product_feature_image {
            fluid(maxWidth: 500) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  `).product_features?.items[0]

  if (!data) return null

  if (
    typeof window !== "undefined" &&
    window.__PRISMIC_PREVIEW_DATA__ &&
    window.__PRISMIC_PREVIEW_DATA__.prismicHomepage
  ) {
    let productFeatureItems = _.find(
      window.__PRISMIC_PREVIEW_DATA__.prismicHomepage.data.body,
      b => {
        return b.slice_type === "homepage_product_features"
      }
    ).items
    if (productFeatureItems) {
      data = productFeatureItems[0]
    }
  }

  const hasCtaLink = () => {
    return (
      data.product_feature_link &&
      data.product_feature_link.url !== "" &&
      data.product_feature_link_text.text !== ""
    )
  }

  return (
    <section className="homepage-product">
      <div className="outer-lines"></div>
      <div className="inner">
        <div className="col asset" data-sal="slide-right" data-sal-delay="300">
          <Img
            fluid={data.product_feature_image.fluid}
            alt={data.product_feature_title.text}
          />
        </div>

        <div className="col text" data-sal="slide-up" data-sal-delay="600">
          <h4>{data.product_feature_title.text}</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: data.product_feature_description.html,
            }}
          ></div>

          {hasCtaLink() && (
            <CustomLink
              link={data.product_feature_link}
              className="button violet"
              data-sal="slide-up"
              data-sal-delay="600"
            >
              {data.product_feature_link_text.text}
            </CustomLink>
          )}

          {!hasCtaLink() && (
            <button
              onClick={triggerRequestDemoClick}
              className="button violet"
              data-sal="slide-up"
              data-sal-delay="600"
            >
              Request Demo
            </button>
          )}
        </div>
      </div>
      <div className="outer-spacer"></div>
    </section>
  )
}

export default HomepageProduct
