import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { linkResolver } from "../_helpers/linkResolver"

const HomepageCaseStudies = () => {
  let data = useStaticQuery(graphql`
    query HomepageCaseStudiesQuery {
      prismicHomepage {
        data {
          case_studies {
            case_study {
              document {
                ... on PrismicCaseStudy {
                  id
                  uid
                  type
                  data {
                    cta_text {
                      html
                      text
                    }
                    customer {
                      document {
                        ... on PrismicCustomer {
                          id
                          data {
                            customer_logo {
                              fluid(maxHeight: 60) {
                                ...GatsbyPrismicImageFluid
                              }
                            }
                            customer_name {
                              html
                              text
                            }
                          }
                        }
                      }
                    }
                    cover_image {
                      fluid(maxWidth: 430) {
                        ...GatsbyPrismicImageFluid
                      }
                    }
                    quote {
                      html
                      text
                    }
                    author {
                      html
                      text
                    }
                    author_title {
                      html
                      text
                    }
                    excerpt {
                      text
                      html
                    }
                    body {
                      __typename
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  data = data.prismicHomepage.data
  if (!data) return null

  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicHomepage) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicHomepage.data
  }

  return (
    <section className="homepage-case-studies">
      <div className="inner">
        {data.case_studies.map((case_study, ind) => {
          if (!case_study.case_study || !case_study.case_study.document) return null
          case_study = case_study.case_study.document

          return <div className="case-study" key={ind} data-sal="slide-up" data-sal-delay={`${ind++ * 300}`}>
            <div className="image">
              <Link to={linkResolver(case_study)}>
                <Img fluid={case_study.data.cover_image.fluid} alt={case_study.data.customer.document.data.customer_name.text} />
              </Link>
            </div>

            <div className="content">
              <div className="quote">
                <p>{case_study.data.quote?.text}</p>
              </div>

              <div className="logo">
                <Img
                  fluid={case_study.data.customer.document.data.customer_logo.fluid}
                  imgStyle={{ objectFit: "contain", objectPosition: "0 0" }}
                  alt={case_study.data.customer.document.data.customer_name.text}
                />
              </div>

              {case_study.data.author &&
              <div className="employee">
                <p>{case_study.data.author?.text}</p>
                <p>{case_study.data.author_title?.text}</p>
              </div>
              }

              <div className="cta">
                <Link to={linkResolver(case_study)} className="button">{case_study.data.cta_text.text}</Link>
              </div>
            </div>
          </div>
        })}
      </div>
    </section>
  )
}

export default HomepageCaseStudies
