import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import _ from "lodash"

import { linkResolver } from "../_helpers/linkResolver"
import { updateImgixParams } from "../_helpers/updateImgixParams"

const HomepageBlog = () => {
  let data = useStaticQuery(graphql`
    query HomepageFeaturedBlogPosts {
      featuredPosts: prismicHomepage {
        data {
          featured_blog_post_headline {
            text
            html
          }
          featured_blog_post_1 {
            document {
              ... on PrismicBlogPost {
                uid
                type
                data {
                  cover_image {
                    fixed(width: 450, height: 450) {
                      ...GatsbyPrismicImageFixed
                    }
                  }
                  snippet {
                    text
                    html
                  }
                  title {
                    html
                    text
                  }
                }
              }
            }
          }
          featured_blog_post_2 {
            document {
              ... on PrismicBlogPost {
                uid
                type
                data {
                  cover_image {
                    fixed(width: 450, height: 450) {
                      ...GatsbyPrismicImageFixed
                    }
                  }
                  snippet {
                    text
                    html
                  }
                  title {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
      allPosts: allPrismicBlogPost(
        sort: { order: DESC, fields: data___date }
        filter: { data: { date: { ne: null } } }
      ) {
        nodes {
          type
          uid
          data {
            title {
              html
              text
            }
            cover_image {
              fixed(width: 450, height: 450) {
                ...GatsbyPrismicImageFixed
              }
            }
            snippet {
              html
              text
            }
            body {
              html
              text
            }
          }
        }
      }
    }
  `)

  if (
    typeof window !== "undefined" &&
    window.__PRISMIC_PREVIEW_DATA__ &&
    window.__PRISMIC_PREVIEW_DATA__.prismicHomepage
  ) {
    let previewData = window.__PRISMIC_PREVIEW_DATA__.prismicHomepage.data
    data.featuredPosts = {
      data: {
        featured_blog_post_1: previewData.featured_blog_post_1,
        featured_blog_post_2: previewData.featured_blog_post_2,
      },
    }
  }

  if (data.allPosts.nodes.length < 2) return null

  let blog_post_1 = data.featuredPosts.data.featured_blog_post_1
  let blog_post_2 = data.featuredPosts.data.featured_blog_post_2

  if (
    (!blog_post_1 || !blog_post_1.document) &&
    (!blog_post_2 || !blog_post_2.document)
  ) {
    blog_post_1 = data.allPosts.nodes[0]
    blog_post_2 = data.allPosts.nodes[1]
  } else {
    if (!blog_post_1 || !blog_post_1.document) {
      blog_post_1 = _.sample(data.allPosts.nodes)
    } else {
      blog_post_1 = blog_post_1.document
    }

    if (!blog_post_2 || !blog_post_2.document) {
      blog_post_2 = _.sample(data.allPosts.nodes)
    } else {
      blog_post_2 = blog_post_2.document
    }
  }

  if (blog_post_1.uid === blog_post_2.uid) {
    blog_post_2 = _.sample(
      _.filter(data.allPosts.nodes, n => {
        return n.uid !== blog_post_1.uid
      })
    )
  }

  return (
    <section className="homepage-blog">
      <div className="outer-lines"></div>
      <div className="inner">
        <div className="row">
          <h2 data-sal="slide-up" data-sal-delay="150">
            {data.featuredPosts.data.featured_blog_post_headline?.text ||
              "Understand your space better, with insights from our team"}
          </h2>
        </div>

        <div className="blog-posts">
          <div className="blog-post" data-sal="slide-up" data-sal-delay="150">
            <Link to={linkResolver(blog_post_1)}>
              <Img
                fixed={updateImgixParams(blog_post_1.data.cover_image.fixed, {
                  fit: "crop",
                  q: 90,
                })}
              />
            </Link>

            <h5>
              <Link to={linkResolver(blog_post_1)}>
                {blog_post_1.data.title.text}
              </Link>
            </h5>
            <div
              dangerouslySetInnerHTML={{
                __html: blog_post_1.data.snippet.html,
              }}
            ></div>
            <Link to={linkResolver(blog_post_1)} className="button violet">
              Read More
            </Link>
          </div>

          <div className="blog-post" data-sal="slide-up" data-sal-delay="150">
            <Link to={linkResolver(blog_post_2)}>
              <Img
                fixed={updateImgixParams(blog_post_2.data.cover_image.fixed, {
                  fit: "crop",
                  q: 80,
                })}
              />
            </Link>

            <h5>
              <Link to={linkResolver(blog_post_1)}>
                {blog_post_2.data.title.text}
              </Link>
            </h5>
            <div
              dangerouslySetInnerHTML={{
                __html: blog_post_2.data.snippet.html,
              }}
            ></div>
            <Link to={linkResolver(blog_post_2)} className="button violet">
              Read More
            </Link>
          </div>
        </div>
      </div>
      <div className="outer-spacer"></div>
    </section>
  )
}

export default HomepageBlog
